<template>
  <a-modal
    title="注册公司"
    :footer="null"
    v-model="visible"
    @ok="handleOk"
    @cancel="visible = true"
    width="700px"
  >
    <a-form-model
      class="formInfo"
      ref="registerForm"
      layout="horizontal"
      :rules="rules"
      :model="registerForm"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      labelAlign="left"
    >
      <a-form-model-item label="公司名称" prop="name">
        <a-input
          type="text"
          placeholder="请输入公司全称"
          v-model="registerForm.name"
          autocomplete="off"
        />
      </a-form-model-item>
      <a-form-model-item label="行业类型" prop="companyIndustryCodeId">
        <a-select
          v-model="registerForm.companyIndustryCodeId"
          placeholder="请选择"
        >
          <a-select-option
            v-for="(item, index) of listIndustry"
            :value="item.id"
            :key="index"
            >{{ item.codeName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="企业规模" prop="companyStaffCodeId">
        <a-select
          v-model="registerForm.companyStaffCodeId"
          placeholder="请选择"
        >
          <a-select-option
            v-for="(item, index) of listStaff"
            :value="item.id"
            :key="index"
            >{{ item.codeName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item class="flexJustifyEnd">
        <div class="flexJustifyEnd">
          <a-button @click="visible = false">取消</a-button>
          <a-button type="primary" @click="fomConfirm" class="btnMargin">
            注册
          </a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createCompany } from "@/api/company.js";
import { getListByCodeKeyPath } from "@/api/code.js";
export default {
  created() {
    this.initCompanyPage();
  },
  data() {
    return {
      listIndustry: {},
      listStaff: {},
      visible: false,
      registerForm: {
        name: "",
        companyIndustryCodeId: "",
        companyStaffCodeId: "",
      },
      rules: {
        name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
      },
      fileList: [],
    };
  },
  methods: {
    show() {
      this.$refs.registerForm && this.$refs.registerForm.resetFields();
      this.registerForm.companyStaffCodeId = this.listStaff
        ? this.listStaff[0].id
        : "";
      this.visible = true;
    },
    handleOk() {
      this.visible = true;
    },
    fomConfirm() {
      let _this = this;
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          console.info(_this);
          createCompany(_this.registerForm).then((res) => {
            _this.$message.success("公司添加成功！");
            const userInfo = _this.$store.getters.getUserInfo;
            var currentCompany = userInfo.currentCompany;
            if (!currentCompany || !currentCompany.id) {
              userInfo.currentCompany = res;
              userInfo.companyName = res.name;
              userInfo.tenantId = res.tenantId;
              userInfo.companyList.push(res);
              _this.$router.push({
                path: "/info",
              });
            } else {
              _this.visible = false;
              userInfo.companyList.push(res);
              _this.$emit("done");
            }
            _this.$store.commit("SET_USER_INFO", userInfo);
          });
        }
      //  else {
      //     _this.$message.error("请检查数据后再提交!");
      //     return false;
      //   }
      });
    },
    checkboxChange() {},
    initCompanyPage() {
      var _this = this;
      ///获取公司行业
      getListByCodeKeyPath("Root_CompanyType")
        .then((res) => {
          _this.listIndustry = res;
        })
        .catch(() => {
          _this.$message.error("获取行业信息失败，请重试～！");
        });
      //获取公司规模
      getListByCodeKeyPath("Root_CompanyStaff")
        .then((res) => {
          _this.listStaff = res;
        })
        .catch(() => {
          _this.$message.error("获取公司规模信息失败，请重试～！");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  margin-left: 10px;
}
</style>
