<template>
  <div class="personalInfo">
    <div style="display: flex">
      <a-form
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 12 }"
        labelAlign="left"
        class="infoForm"
      >
        <a-form-item label="姓名">
          <p>{{ userInfo.fullName }}</p>
        </a-form-item>
        <a-form-item label="手机号">
          <p>{{ userInfo.phoneNumber }}</p>
        </a-form-item>
        <a-form-item label="当前公司">
          <p class="companyName">{{ currentCompany.name }}</p>
        </a-form-item>
      </a-form>
      <a-form
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 12 }"
        labelAlign="left"
        class="infoForm"
      >
        <a-form-item label="">
          <div class="title" @click="whenEdit">编辑</div>
        </a-form-item>
        <a-form-item label="邮箱" class="emailMargin">
          <p>{{ userInfo.email }}</p>
        </a-form-item>
      </a-form>
    </div>
    <EditInfoPopup
      ref="editInfoRef"
      @submit="whenChangePersonal"
      @cancel="whenCancel"
    />
  </div>
</template>

<script>
import EditInfoPopup from "./EditInfoPopup.vue";
export default {
  created() {
    this.editCtrl.visible = false;
  },
  components: {
    EditInfoPopup,
  },
  data() {
    return {
      editCtrl: {
        visible: false,
      },
    };
  },
  methods: {
    whenEdit() {
      this.$refs.editInfoRef.visible = true;
    },
    whenChangePersonal() {
      this.$refs.editInfoRef.visible = false;
    },
    whenCancel() {
      this.$refs.editInfoRef.visible = false;
      this.$refs.editInfoRef.editForm = { ...this.userInfo };
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo || {};
    },
    currentCompany() {
      return this.userInfo.currentCompany || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.personalInfo {
  margin-top: 10px;
}
.infoForm {
  position: relative;
  flex: 1;
  .companyName {
    font-weight: 600;
    font-size: 14px;
  }
  .title {
    color: $blue_color;
    cursor: pointer;
    z-index: 999;
    display: inline-block;
  }
  ::v-deep.ant-form-item {
    margin-bottom: 10px;
  }
  .emailMargin {
    margin-top: 55px;
  }
}
</style>
