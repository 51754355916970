<template>
  <div>
    <a-button type="primary" class="createBtn" @click="createCompany"
      >创建公司</a-button
    >
    <a-table
      :columns="columns"
      :data-source="data"
      :showHeader="false"
      rowKey="id"
      :loading="loading"
      :pagination="pagination"
      @change="whenChangePage"
    >
      <template slot="operation" slot-scope="text, record">
        <!--
        <template v-if="record.id === currCompanyId && isPersonal">
          <div class="flexCenter setDefault">
            <span>当前公司</span>
          </div>
        </template>
        v-else
        -->
        <template>
          <div class="flexCenter enterCompany">
            <i class="iconfont icon-jiantouarrow484"></i>
            <span @click="enterCompany(record)">进入该公司</span>
          </div>
        </template>
      </template>

      <template slot="default" slot-scope="text, record">
        <template v-if="record.isDefault">
          <div class="flexCenter setDefault">
            <i class="iconfont icon-qizi default"></i>
            <span>当前默认</span>
          </div>
        </template>
        <template v-else>
          <div class="flexCenter setDefault">
            <i class="iconfont icon-qizi"></i>
            <span @click="setDefaultCompany(record)">设置默认</span>
          </div>
        </template>
      </template>
    </a-table>
    <CreateCompanyPopup @done="getCurrentCompanys" ref="createCompanyRef" />
  </div>
</template>

<script>
// 员工
const columns = [
  {
    key: "name",
    title: "公司名称",
    dataIndex: "name",
  },
  {
    key: "operation",
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
  {
    key: "default",
    title: "默认",
    dataIndex: "default",
    scopedSlots: { customRender: "default" },
  },
];
import store from "@/store";
import CreateCompanyPopup from "./CreateCompanyPopup.vue";
import { setDefault } from "@/api/user.js";
import { switchCompanyAsync } from "@/api/account";
import { getCurrentCompanyList } from "@/api/companyuser";

export default {
  created() {
    this.loading = true;
    this.getCurrentCompanys();
  },
  components: {
    CreateCompanyPopup,
  },
  data() {
    return {
      data: [],
      currCompanyId: store.getters.getUserInfo.companyId,
      columns,
      selectedRowKeys: [],
      loading: false,
      /**列表分页参数设置 */
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
    };
  },
  methods: {
    // 创建公司
    createCompany() {
      this.$refs.createCompanyRef.show();
    },
    /**
     * 分页切换事件
     * @param {Object} pagination 分页对象
     */
    whenChangePage(pagination) {
      this.pagination.current = pagination.current;
      this.getCurrentCompanys();
    },
    getCurrentCompanys(parm) {
      var _this = this;
      if (!parm) {
        parm = {};
      }
      parm.maxResultCount = this.pagination.pageSize;
      parm.skipCount = (this.pagination.current - 1) * this.pagination.pageSize;
      getCurrentCompanyList(parm)
        .then((res) => {
          _this.data = res.items;
          var pagination = { ..._this.pagination };
          pagination.total = res.totalCount;
          _this.pagination = pagination;
          _this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          _this.$message.error("获取当前公司列表出现异常，请登录后重试！");
        });
    },
    enterCompany(paramate) {
      var _this = this;
      this.loading = true;
      switchCompanyAsync(paramate)
        .then(() => {
          _this.$message.success("切换成功～！");
          this.loading = false;
        })
        .catch(() => {
          _this.$message.error("切换失败，请重新登录或尝试其他公司～！");
          this.loading = false;
        });
    },
    setDefaultCompany(paramate) {
      this.loading = true;
      var companyList = store.getters.getUserInfo.companyList;
      setDefault(paramate.id)
        .then(() => {
          this.loading = false;
          companyList.forEach(function (element) {
            if (paramate.id === element.id) {
              element.isDefault = true;
            } else {
              element.isDefault = false;
            }
          });
          this.$message.success("设置成功");
          this.getCurrentCompanys();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
  computed: {
    isPersonal() {
      return this.$router.name === "info";
    },
  },
};
</script>

<style lang="scss" scoped>
.default {
  color: $blue_color;
  cursor: pointer;
}
.enterCompany:hover {
  color: $blue_color;
  cursor: pointer;
}
.setDefault:hover {
  color: $blue_color;
  cursor: pointer;
}
.createBtn {
  margin-bottom: 10px;
}
</style>
