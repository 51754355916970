<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      class="table"
      @change="whenChangePage"
      :pagination="pagination"
      :loading="loading"
    >
      <template slot="invivateTime" slot-scope="text">
        <span>{{ filterTime(text) }}</span>
      </template>
      <template slot="InvivateState" slot-scope="text, item">
        <p v-if="item.invitedState == 0">待处理</p>
        <p v-if="item.invitedState == 1">已加入</p>
        <p v-if="item.invitedState == 2">已拒绝</p>
      </template>
      <template
        slot="operation"
        slot-scope="text, item"
        v-if="item.invitedState === 0"
      >
        <a-button type="primary" @click="AgreeInvite(item)">同意</a-button>
        <a-button class="refuse" @click="refuseInvite(item)">拒绝</a-button>
      </template>
      <div slot="no" slot-scope="text, record, index">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </div>
    </a-table>
  </div>
</template>

<script>
// 员工
const columns = [
  {
    key: "companyName",
    title: "公司名称",
    dataIndex: "companyName",
  },
  {
    key: "invivateFullName",
    title: "邀请人",
    dataIndex: "invivateFullName",
  },
  {
    key: "invivateTime",
    title: "邀请时间",
    dataIndex: "invivateTime",
    scopedSlots: { customRender: "invivateTime" },
  },
  {
    key: "invitedState",
    title: "状态",
    dataIndex: "invitedState",
    scopedSlots: { customRender: "InvivateState" },
  },
  {
    key: "operation",
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

import {
  updateCompanyUserInvitation,
  getCompanyUserInvitationList,
} from "@/api/companyuserinvitation";
import { getCompany } from "@/api/company";
import moment from "moment";
export default {
  created() {
    this.getCompanyUserInvitationList();
  },
  data() {
    return {
      /**列表分页参数设置 */
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      data: [],
      columns,
      loading: false,
    };
  },
  methods: {
    /**
     * 格式化时间日期
     * @param {datatime} time (时间日期)
     */
    filterTime(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    //同意加入
    AgreeInvite(paramate) {
      // paramate.isAccpet = true;
      var param = {
        invitedState: 1,
      };
      var _this = this;
      updateCompanyUserInvitation(paramate.id, param)
        .then((res) => {
          _this.$message.success("成功加入～！");
          const userInfo = this.$store.getters.getUserInfo;
          userInfo.companyList.push(res);
          this.$store.commit("SET_USER_INFO", userInfo);
          if (userInfo.companyList.length == 1) {
            location.href = "/";
          } else {
            _this.getCompanyUserInvitationList();
          }
        })
        .catch(() => {
          _this.$message.error("加入失败，请重新登录后重试～！");
        });
    },
    getCompanyUserInvitationList() {
      this.loading = true;
      var _this = this;
      var param = {
        skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
        maxResultCount: this.pagination.pageSize,
      };
      getCompanyUserInvitationList(param)
        .then((res) => {
          _this.data = res.items;
          //res.totalCount 总条数
          _this.pagination.total = res.totalCount;
          _this.loading = false;
        })
        .catch(() => {
          _this.$message.error("获取当前受邀请列表出现异常，请登录后重试！");
          _this.loading = false;
        });
    },
    whenChangePage(pagination) {
      this.pagination.current = pagination.current;
      this.getCompanyUserInvitationList();
    },
    //   拒绝邀请
    refuseInvite(paramate) {
      var _this = this;
      this.$confirm({
        title: "提示",
        content: "确定拒绝 " + paramate.companyName + " 公司邀请吗？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          var param = {
            invitedState: 2,
          };
          // paramate.isAccpet = false;
          updateCompanyUserInvitation(paramate.id, param)
            .then(() => {
              _this.getCompanyUserInvitationList();
              _this.$message.success("已拒绝加入");
            })
            .catch(() => {
              _this.$message.error("拒绝失败，请重新登录后重试～！");
            });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.refuse {
  margin-left: 10px;
}
</style>
