import { getRequest, postRequest } from "@/utils/http";

/** 查询硬件所有用户集合 */
export const getUserListAsync = (parm) =>
  getRequest(`/identity/user/getlist`, parm);

/**
 * 设置默认公司
 * @param {object} parm 公司ID
 * @returns
 */
export const setDefault = (companyId) =>
  postRequest(`/identity/user/setdefault/${companyId}`);
