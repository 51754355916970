/* eslint-disable no-unused-vars */

import { postRequest, putRequest } from "@/utils/http";
/**
 * 修改个人信息
 * @param {Object} params 个人信息
 * @returns Promise
 */
export function updateAsync(params) {
  return putRequest("/identity/personal/update", {
    name: params.name,
    surName: params.surname,
    email: params.email,
  });
}
