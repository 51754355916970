<template>
  <a-modal
    title="编辑个人资料"
    :footer="null"
    :destroyOnClose="true"
    :visible="visible"
    :confirmLoading="loading"
    @cancel="$emit('cancel')"
    width="700px"
  >
    <a-form-model
      id="components-form-demo-normal-login"
      class="formInfo"
      ref="editForm"
      layout="horizontal"
      :rules="rules"
      :model="editForm"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      labelAlign="left"
    >
      <a-form-model-item label="当前公司">
        <p class="companyName">{{ currentCompany.name }}</p>
      </a-form-model-item>
      <a-form-model-item label="姓名">
        <a-form-model-item
          prop="surname"
          :style="{ display: 'inline-block', width: '47%' }"
        >
          <a-input
            type="text"
            placeholder="请输入姓氏"
            v-model="editForm.surname"
            autocomplete="off"
            style="width: 100%"
          >
          </a-input>
        </a-form-model-item>
        <span :style="{ display: 'inline-block', width: '24px' }">&nbsp;</span>
        <a-form-model-item
          prop="name"
          :style="{ display: 'inline-block', width: '48%' }"
        >
          <a-input
            type="text"
            placeholder="请输入名称"
            v-model="editForm.name"
            autocomplete="off"
            style="width: 100%"
          >
          </a-input>
        </a-form-model-item>
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phoneNumber">
        <a-input
          type="text"
          placeholder="请输入手机号"
          v-model="editForm.phoneNumber"
          autocomplete="off"
          :disabled="true"
        />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email">
        <a-input
          type="text"
          placeholder="请输入邮箱"
          v-model="editForm.email"
          autocomplete="off"
        />
      </a-form-model-item>
      <a-form-model-item class="flexJustifyEnd">
        <div class="flexJustifyEnd">
          <a-button @click="$emit('cancel')"> 取消 </a-button>
          <a-button type="primary" @click="whenSubmit" class="btnMargin">
            确认
          </a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
/* eslint-disable no-unused-vars */
import { isTelephone, isEmail } from "@/utils/tools";
import { updateAsync } from "@/api/personalCenter";
export default {
  data() {
    let isPhone = (rule, value, callback) => {
      isTelephone(rule, value, callback);
    };
    let namevalidator = (rule, value, cbfn) => {
      var _this = this;
      var fullName = _this.editForm.surname + _this.editForm.name;
      if (!value) cbfn("名称不能为空");
      if (new TextEncoder().encode(fullName).length > 20)
        cbfn("姓名不能超过20字符!");
      cbfn();
    };
    let surnamevalidator = (rule, value, cbfn) => {
      var _this = this;
      var fullName = _this.editForm.surname + _this.editForm.name;
      if (!value) cbfn("姓氏不能为空");
      if (new TextEncoder().encode(fullName).length > 20)
        cbfn("姓名不能超过20字符!");
      cbfn();
    };
    let emailvalidator = (rule, value, cbfn) => {
      var _this = this;
      if (!value) cbfn("请输入电子邮箱");
      isEmail(rule, value, cbfn);
    };
    return {
      editForm: {},
      errData: [],
      rules: {
        name: [{ required: true, validator: namevalidator, trigger: "blur" }],
        surname: [
          { required: true, validator: surnamevalidator, trigger: "blur" },
        ],
        email: [{ required: true, validator: emailvalidator, trigger: "blur" }],
      },
      visible: false,
      loading: false,
    };
  },
  methods: {
    onCancel() {
      this.$emit("cancel");
    },
    whenSubmit() {
      let _this = this;
      this.$refs.editForm.validate((valid) => {
        console.info(this);
        if (valid) {
          this.loading = true;
          console.info(_this);
          _this.editForm.fullName =
            _this.editForm.surname + _this.editForm.name;
          updateAsync(_this.editForm)
            .then((res) => {
              this.loading = false;
              this.$store.commit("SET_PERSONAL", this.editForm);
              this.$message.success("修改成功");
              this.$emit("submit");
            })
            .catch((err) => {
              this.loading = false;
              this.$message.error(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo || {};
    },
    currentCompany() {
      return this.userInfo.currentCompany || {};
    },
  },
  mounted() {
    this.editForm = { ...this.userInfo };
  },
};
</script>

<style lang="scss" scoped>
.companyName {
  font-size: 14px;
  font-weight: 600;
}
</style>
