import { getRequest, postRequest } from "@/utils/http";

/**
 * 查询用户的所有关联公司集合
 * @param {object} params
 * */
export const getUserCompanyList = (params) =>
  getRequest(`/identity/company/GetUserCompanyList`, params);

/**
 * 查询公司
 * @param {Guid} id 公司Id
 * @returns
 */
export const getCompany = (id) => getRequest(`/identity/company/${id}`);

/**
 * 创建新公司
 * @param {object} params 公司信息
 * @returns 新公司信息
 */
export function createCompany(params) {
  return postRequest("/identity/company", params);
}
