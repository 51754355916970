<template>
  <div>
    <div class="bgStyle">
      <div class="pageTitle">个人资料</div>
      <PersonalInfo />
    </div>
    <a-tabs :default-active-key="1" class="bgStyle" @change="tabChange">
      <a-tab-pane :key="item.key" v-for="item in companyTabList">
        <span slot="tab" class="title">{{ item.name }} </span>
        <JoinedCompany ref="JoinedCompany" v-if="item.key === 1" />
        <Invited v-else />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import PersonalInfo from "./components/PersonalInfo.vue";
import JoinedCompany from "./components/JoinedCompany.vue";
import Invited from "./components/Invited.vue";
export default {
  components: {
    PersonalInfo,
    JoinedCompany,
    Invited,
  },
  data() {
    return {
      companyTabList: [
        { key: 1, name: "我加入的公司" },
        { key: 2, name: "受邀请" },
      ],
    };
  },
  methods: {
    tabChange(val) {
      if (val == 1) {
        this.$refs.JoinedCompany[0].getCurrentCompanys();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: 600;
}
</style>
