import { getRequest, updateRequest } from "@/utils/http";

/**
 * 查询邀请信息集合
 * @param {object} parm [用户ID]
 */
export const getCompanyUserInvitationList = (parm) =>
  getRequest(`/identity/companyuserinvitation/getList`, parm);

/** 修改数据字典 */
export const updateCompanyUserInvitation = (id, parm) =>
  updateRequest(`/identity/companyuserinvitation/update/${id}`, parm);
